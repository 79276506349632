var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"wrapper"},[_c('button',{staticClass:"play-pause-icon",class:[
        { 'is-small': _vm.isSmall },
        `${_vm.iconType}`,
        { active: _vm.isActive },
        `${_vm.animation}`,
      ]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPlayButton),expression:"showPlayButton"}],staticClass:"icon-wrapper",class:{
          'medium-icon-wrapper': _vm.format.bubbleSize === 90,
          'small-icon-wrapper': _vm.format.bubbleSize === 60,
        },on:{"click":_vm.click}},[(_vm.iconType === 'outline')?_c('PlayOutline',{attrs:{"color":_vm.color,"secondary-color":_vm.secondaryColor,"opacity":_vm.opacity}}):(_vm.iconType === 'round')?_c('PlayRound',{attrs:{"color":_vm.color,"secondary-color":_vm.secondaryColor,"opacity":_vm.opacity}}):(_vm.iconType === 'square')?_c('PlaySquare',{attrs:{"color":_vm.color,"secondary-color":_vm.secondaryColor,"opacity":_vm.opacity}}):_c('PlayMinimal',{attrs:{"color":_vm.color}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPlayButton),expression:"!showPlayButton"}],staticClass:"icon-wrapper",on:{"click":_vm.click}},[(_vm.iconType === 'outline')?_c('PauseOutline',{attrs:{"color":_vm.color,"secondary-color":_vm.secondaryColor,"opacity":_vm.opacity}}):(_vm.iconType === 'round')?_c('PauseRound',{attrs:{"color":_vm.color,"secondary-color":_vm.secondaryColor,"opacity":_vm.opacity}}):(_vm.iconType === 'square')?_c('PauseSquare',{attrs:{"color":_vm.color,"secondary-color":_vm.secondaryColor,"opacity":_vm.opacity}}):_c('PauseMinimal',{attrs:{"color":_vm.color}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }